import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#f8f8f8"
            }
        }
    },
    fonts: {
        heading: "Open Sans, sans-serif",
        body: "Open Sans, sans-serif",
    },
    colors: {
        brand: '#339d52'
    },
    components: {
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: '#339d52'
                }
            }
        }
    }
})

export default ChakraTheme;
