import React from "react";
import {Box, Heading, Image, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

export const SuccessStory = (props) => {
    return (
        <Box borderWidth="1px" rounded="lg" overflow="hidden">
            <Box>
                <Box p={2} background={'#339d52'} color={'white'} fontWeight={'bold'} fontSize={'sm'}>
                    <FormattedMessage id={'successStory.headline'}/>
                </Box>

                <Heading size={['lg', 'md']} p={3}>{props.title}</Heading>

                <Box alignItems={'center'}>
                    <Image display={'inline'} src={props.image} alt={props.title}/>
                </Box>

                <Text p={2} fontSize={'md'}>{props.description}</Text>
            </Box>
        </Box>
    );
};
